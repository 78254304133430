.MuiPaper-root
  > div
  > div
  > .MuiToolbar-root:first-of-type
  > div:first-of-type {
  display: none;
}

.MuiPaper-root > div > div > .MuiToolbar-root {
  padding-left: 17px;
}

.MuiPaper-root > div > div > .MuiToolbar-root:first-of-type > div:nth-child(2) {
  padding-left: 0;
  margin-left: 0;
}

input::-ms-clear {
  display: none;
  height: 0;
  width: 0;
}
